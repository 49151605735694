globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"8d276b6d82f8c3d3bca6928ace1a9e64887be5a1"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://efa48f40d44d8448145f39c181f9aa86@o4506435970662400.ingest.sentry.io/4506435972300800",
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.1,
  tracePropagationTargets: ["localhost", /^\//, process.env.API],
});
